var subMenuClass = "Submenu--is-active";
var dropdownActiveClass = "Dropdown--is-open";
var dropdownClass;
var dropdownObj;
var lastOpenDropdown;

var keLazy = (function foo() {
  new LazyLoad({
    elements_selector: "[data-src]",
    data_src: "src",
    callback_load: function(element) {
      element.parentNode.classList.add("image--is-loaded");
      objectFitImages(element);
    }
  });

  return foo;
})();

var keDropdown = (function foo() {
  dropdownObj = document.querySelectorAll("[data-dropdown]");
  for (var i = 0; i < dropdownObj.length; i++) {
    var thisAttr = dropdownObj[i].getAttribute("data-dropdown");

    dropdownObj[i].onclick = function(event) {
      dropdownClass = thisAttr + "--is-active";
      lastOpenDropdown = event.target;

      closeDropdowns();

      this.classList.toggle(dropdownActiveClass);
      document.body.classList.toggle(dropdownClass);
    };
  }

  return foo;
})();

// On Windows, the scrollbar of .Menu takes up some space that we need to take
// into account for .Header-toggle (since that element is using `position:
// fixed`). It would be more elegant to solve this in CSS, but this is a
// functional fix at a late stage
$(window).on('resize.headertoggle', function() {
  var $headerToggle = $('.Header-toggle');

  if ($('.Menu:visible').length) {
    var translateX = $('body').prop('clientWidth') - $('.Menu').prop('clientWidth');
    $('.Header-toggle').css('transform', 'translateX(' + -translateX + 'px)');
  } else {
    $('.Header-toggle').css('transform', '');
  }
});

$('.Menu-content').on('animationend', function() {
  $(window).trigger('resize.headertoggle');
});

var keToggle = (function foo() {
  var toggleObj = document.querySelectorAll("[data-toggle]");
  for (var i = 0; i < toggleObj.length; i++) {
    toggleObj[i].onclick = function() {
      var thisAttr = this.getAttribute("data-toggle");
      if ( thisAttr == 'Menu' && document.body.classList.contains('Menu--is-active') ) {
          window.location.hash = '';
          document.body.classList.remove(thisAttr + "--is-active");
          $(window).trigger('resize.headertoggle');
      } else if ( thisAttr == 'Menu' ) {
          window.location.hash = 'menu';
          document.body.classList.add(thisAttr + "--is-active");
          $(window).trigger('resize.headertoggle');
      } else {
          document.body.classList.toggle(thisAttr + "--is-active");
      }
    };
  }
  return foo;
})();

window.addEventListener("hashchange", function(){
    if ( window.location.hash == '' ) {
        document.body.classList.remove('Menu--is-active');
    }
}, false);

var keSubMenu = (function foo() {
  /* Intercept link and show submenu */
  var subMenuLinks = document.querySelectorAll("[data-barba='submenu']");

  for (var i = 0; i < subMenuLinks.length; i++) {
    subMenuLinks[i].onclick = function(event) {
      var previousSubMenu = document.querySelector(".Submenu--is-active");
      event.stopImmediatePropagation();
      event.preventDefault();

      if (previousSubMenu && previousSubMenu !== event.target.parentElement) {
        previousSubMenu.classList.remove(subMenuClass);
      }

      event.target.parentElement.classList.toggle(subMenuClass);
    };

  }

  /* Close menu for normal links */
  var normalLinks = document.querySelectorAll("[data-barba='reload']");
  for (var i = 0; i < normalLinks.length; i++) {
    normalLinks[i].onclick = function(event) {
      document.body.classList.remove("Menu--is-active");
    };
  }

  return foo;
})();

var keBody = (function foo() {
  ["click"].forEach(function(eventType) {
    document.addEventListener(eventType, function(event) {
      if (lastOpenDropdown !== undefined) {
        var isClickInside = lastOpenDropdown.contains(event.target);

        if (!isClickInside) {
          closeDropdowns();
        }
      }

      /* Close submenu if clicked outside of element */
      var activeSubMenu = document.querySelector(".Submenu--is-active");

      if (activeSubMenu !== null) {
        activeSubMenu.classList.remove(subMenuClass);
      }
    });
  });

  $("a[href]").each(function() {
    if (/konstepidemin\.se/.test(this.hostname) && /\.(docx?|pdf|xls)$/.test(this.pathname)) {
      $(this).attr("target", "_blank");
    }
  });

  $(".wp-image-123177650").each(function() {
    var $parent = $(this).parents("p");
    var content = $parent.html();
    var $div = $("<div></div>").html(content);
    $parent.before($div);
    $parent.remove();
  });

  return foo;
})();

function closeDropdowns() {
  /* Close dropdown if clicked outside of element */
  document.body.classList.remove(dropdownClass);

  dropdownObj.forEach(function(instance) {
    instance.classList.remove(dropdownActiveClass);
  });
}

var keSlider = (function foo() {
  var sliderEl = document.querySelectorAll("[data-slider]");
  for (var i = 0; i < sliderEl.length; i++) {

    var sliderObj = new Flickity(sliderEl[i], {
      setGallerySize: false,
      imagesLoaded: true,
      prevNextButtons: false
    });

    sliderObj.on( 'staticClick', function( event, pointer, cellElement, cellIndex ) {
      /* Dismiss if cell was not clicked */
      if ( !cellElement ) {
        return;
      }
      /* Force rewind: true if last item */
      sliderObj.next(true);
    });
  }

  return foo;
})();

var keInview = (function foo() {
  $("body").addClass("Inview--watching");

  $("[data-inview]").each(function(i, element) {
    var slug = element.dataset.inview;
    var watcher = scrollMonitor.create(element);

    watcher.enterViewport(function() {
      element.classList.add(slug + "--entering");
    });
    watcher.fullyEnterViewport(function() {
      element.classList.add(slug + "--in-view");
    });
    watcher.partiallyExitViewport(function() {
      element.classList.remove(slug + "--in-view");
    });
    watcher.exitViewport(function() {
      element.classList.remove(slug + "--entering");
    });
  });

  return foo;
})();

Barba.Pjax.start();
Barba.Prefetch.init();

Barba.Pjax.originalPreventCheck = Barba.Pjax.preventCheck;

Barba.Pjax.preventCheck = function(event, element) {
  if (!Barba.Pjax.originalPreventCheck(event, element)) {
    return false;
  }

  // Avoid PJAX behaviour for links that lead to wp-admin
  return !/\/wp-admin/.test(element.pathname);
};

var barbaClicked = false;

Barba.Dispatcher.on("linkClicked", function() {
  barbaClicked = true;
});

addEventListener("popstate", function (event) {
  barbaClicked = false;
});

/* Event based here */
Barba.Dispatcher.on("newPageReady", function(currentStatus, oldStatus, container, newPageRawHTML) {
  keLazy();
  keToggle();
  keDropdown();
  keSubMenu();
  keBody();

  if ($("#wpadminbar").length) {
    var $newDoc = $($.parseHTML(newPageRawHTML));
    var $adminbar = $newDoc.filter("#wpadminbar");
    $("#wpadminbar").remove();
    $("body").append($adminbar);
  }
});

/* Direct DOM manupulation here */
Barba.Dispatcher.on("transitionCompleted", function() {
  keSlider();
  keInview();

  /* Don"t change scroll position if back button pressed */
  if (barbaClicked) {
    window.scroll({
      top: 0,
      behaviour: "smooth"
    });
  }
});

var pageExitClass = "page--exit";
var pageEnterClass = "page--enter";
var pageTransition = Barba.BaseTransition.extend({
  start: function() {
    Promise
      .all([this.newContainerLoading, this.exit()])
      .then(this.enter.bind(this));
  },
  exit: function() {
    var wrapper = this.oldContainer.parentNode;

    wrapper.classList.toggle(pageExitClass);

    return new Promise(function(resolve, reject) {
      wrapper.addEventListener("transitionend", function() {
        wrapper.classList.remove(pageExitClass);
        resolve();
      });
    });
  },
  enter: function() {
    var wrapper = this.newContainer.parentNode;

    wrapper.classList.toggle(pageEnterClass);

    /* .Loader transition: .25s completed */
    setTimeout(function() {
      wrapper.classList.remove(pageEnterClass);
    }, 250);

    this.done();
  }
});

Barba.Pjax.getTransition = function() {
  return pageTransition;
};

$(document).on("submit", ".js-newsletterForm", function() {
  var $form = $(this);
  var formData = $form.serialize();

  var $responseMessage = $form.find(".Menu-formResponseMessage");
  var $disabledElements = $form
    .find("input, textarea, select, button")
    .not(":disabled");

  $responseMessage.removeClass("is-visible is-success is-error");
  $disabledElements.attr("disabled", true);
  $form.addClass("is-disabled is-loading");

  $.ajax({
    url: $form.attr("action"),
    method: $form.attr("method"),
    data: formData,
    success: function(data) {
      $form.removeClass("is-disabled is-loading");
      $disabledElements.removeAttr("disabled");
      $responseMessage.text(data.message).addClass("is-visible is-success");
    },
    error: function(data) {
      $form.removeClass("is-disabled is-loading");
      $disabledElements.removeAttr("disabled");

      var message =
        data.responseJSON && data.responseJSON.message
          ? data.responseJSON.message
          : 'Något verkar ha gott fel. Försök igen om en liten stund. Om problemet kvarstår, kontakta gärna <a href="mailto:nyhetsbrevet@konstepidemin.se">nyhetsbrevet@konstepidemin.se</a> direkt.';

      $responseMessage.html(message).addClass("is-visible is-error");
    }
  });

  return false;
});

$(document).on("change", ".Filter .Dropdown-item input", function() {
  var $form = $(this).parents(".Filter");

  var inputName = $(this).attr("name")
  var $otherInputs = $form.find("input[name='" + inputName + "']").not(this);
  $otherInputs.prop("checked", false);

  var formQuery = $form.serializeArray().filter(function(input) {
    return input.value;
  }).map(function(input) {
    return input.name + "=" + encodeURIComponent(input.value);
  }).join("&");

  if (formQuery) {
    location.search = "?" + formQuery;
  } else {
    location.search = "";
  }
});

$(function() {
  var $images = $(".Offers-image, .Generic-image, .Figure-image, .Feed-image, .Feed-artwork, .Detail-image, .Detail-sliderImage, .Video-content, .Partners-image").filter("[src]");
  objectFitImages($images.get());
});
